<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
            <table border="0" cellspacing="0" cellpadding="0" class="kanun">
              <tbody>
                <tr>
                  <td >
                    <div>
                      <table border="0"  cellspacing="0" cellpadding="0">
                        <tbody>
                          <tr>
                            <td style="text-align: left">
                              <p>4 Ocak 2020 CUMARTESİ</p>
                            </td>
                            <td style="text-align: center">
                              <p ><strong>Resmî Gazete</strong></p>
                            </td>
                            <td style="text-align: right">
                              <p>Sayı : 30998</p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3"  >
                              <br>
                              <p style="text-align: center"><strong>YÖNETMELİK</strong></p>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" >
                              <br><br><br>
                              <p style="text-align: center">Ticaret Bakanlığından:</p>
                              <p style="text-align: center">TİCARİ İLETİŞİM VE TİCARİ ELEKTRONİK İLETİLER HAKKINDA</p>
                              <p style="text-align: center">YÖNETMELİKTE DEĞİŞİKLİK YAPILMASINA</p>
                              <p style="text-align: center">DAİR YÖNETMELİK</p>
                              <br><br>
                              <p><strong>MADDE 1 -</strong> 15/7/2015 tarihli ve 29417 sayılı
                                Resmî Gazete'de yayımlanan Ticari İletişim ve Ticari
                                Elektronik İletiler Hakkında Yönetmeliğin 2 nci maddesinin
                                ikinci fıkrasının (b) bendinde yer alan "Vakıf
                                üniversitelerinin" ibaresinden sonra gelmek üzere
                                "ve diğer özel eğitim ve öğretim kurumlarının"
                                ibaresi eklenmiştir.</p>
                              <p><strong>MADDE 2 - </strong>Aynı Yönetmeliğin 4
                                üncü maddesi aşağıdaki şekilde değiştirilmiştir.</p>
                              <p>"<strong>MADDE 4 - </strong>(1) Bu Yönetmelikte
                                geçen;</p>
                              <p>a) Alıcı: Tüketiciyi ya da meslekî veya diğer
                                amaçlarla hareket eden gerçek veya tüzel kişiyi,
                              </p>
                              <p>b) Aracı hizmet sağlayıcı: Başkalarına ait iktisadî ve ticari
                                faaliyetlerin yapılmasına elektronik ticaret ortamını sağlayan
                                gerçek ve tüzel kişileri,</p>
                              <p>c) Bakanlık: Ticaret Bakanlığını,</p>
                              <p>ç) Bayi işletme: Sözleşmeye dayalı olarak bir
                                işletmenin mal ve hizmetlerinin satışına aracılık eden ve kendi
                                adına bağımsız çalışan işletmeyi,</p>
                              <p>d) Elektronik iletişim adresi: Elektronik posta ve telefon numarası
                                gibi elektronik ortamda münhasıran iletişim kurmayı sağlayan
                                adresi,</p>
                              <p>e) Elektronik iletişim araçları: İnternet ve diğer iletişim
                                ağları üzerinden iletilerin gönderilmesine, alınmasına
                                veya saklanmasına imkân sağlayan bilgisayar, telefon, faks,
                                otomatik arama makineleri gibi her türlü cihazı,</p>
                              <p>f) Elektronik ortam: Verilerin sayısallaştırılarak işlenmesi,
                                saklanması ve iletilmesinin sağlandığı ortamı,</p>
                              <p>g) Elektronik ticaret: Fizikî olarak karşı karşıya
                                gelmeksizin, elektronik ortamda gerçekleştirilen
                                çevrim içi iktisadi ve ticari her türlü
                                faaliyeti,</p>
                              <p>ğ) Hizmet sağlayıcı: Elektronik ticaret faaliyetinde bulunan
                                gerçek ya da tüzel kişileri,</p>
                              <p>h) İl müdürlüğü: Ticaret il
                                müdürlüğünü,</p>
                              <p>ı) İl müdürü: Ticaret il
                                müdürünü,</p>
                              <p>i) İş günü: Ulusal bayram ile genel ve hafta sonu tatil
                                günleri hariç diğer günleri,</p>
                              <p>j) İYS Numarası: İYS'ye kayıt olan tüm hizmet sağlayıcı
                                ve markalarına ayrı ayrı atanan tekil numarayı,</p>
                              <p>k) Kanun: 6563 sayılı Elektronik Ticaretin Düzenlenmesi
                                Hakkında Kanunu,</p>
                              <p>l) Kuruluş: Bakanlıkça ticari elektronik ileti yönetim
                                sistemini kurmakla yetkilendirilen kuruluşu,</p>
                              <p>m) MERSİS: Merkezi Sicil Kayıt Sistemini,</p>
                              <p>n) Özel yetkili işletme: Sözleşmeye dayalı olarak bir
                                işletmenin, yönetim ve organizasyon ile dağıtım veya pazarlama
                                teknolojileri gibi konularda bilgi ve desteğini almak suretiyle bu
                                işletmenin mal veya hizmetinin satışı üzerindeki imtiyaz
                                hakkını bedel, bölge ve süre gibi belirli şartlar ve
                                sınırlamalar dâhilinde kullanan bağımsız ticari işletmeyi,</p>
                              <p>o) Ticari elektronik ileti: Telefon, çağrı merkezleri, faks,
                                otomatik arama makineleri, akıllı ses kaydedici sistemler,
                                elektronik posta, kısa mesaj hizmeti gibi vasıtalar kullanılarak
                                elektronik ortamda gerçekleştirilen ve ticari
                                amaçlarla gönderilen veri, ses ve görüntü
                                içerikli iletileri,</p>
                              <p>ö) Ticari elektronik ileti yönetim sistemi (İYS): Ticari
                                elektronik ileti onayı alınmasına, reddetme hakkının kullanılmasına
                                ve şikâyet süreçlerinin yönetilmesine
                                imkân tanıyan sistemi,</p>
                              <p>p) Ticari iletişim: Alan adları ve elektronik posta adresi dışında,
                                meslekî veya ticari faaliyet kapsamında kazanç
                                sağlamaya yönelik olarak elektronik ticarete ilişkin her
                                türlü iletişimi,</p>
                              <p>r) URL adresi: İlgili içeriğin internet gibi bir ağ
                                üzerinde bulunduğu tam adresi,</p>
                              <p>ifade eder."</p>
                              <p><strong>MADDE 3 - </strong>Aynı Yönetmeliğin İkinci
                                Bölümünün başlığı "Ticari Elektronik
                                İletiler ve İYS" olarak değiştirilmiştir.</p>
                              <p><strong>MADDE 4 - </strong>Aynı Yönetmeliğin 5 inci
                                maddesinin birinci fıkrasında yer alan "ticari elektronik
                                iletiler için kendisi tarafından" ibaresinden sonra
                                gelmek üzere "ya da İYS üzerinden" ibaresi
                                eklenmiş ve aynı maddeye aşağıdaki fıkralar eklenmiştir.</p>
                              <p>"(2) Ticari elektronik ileti göndermek isteyen
                                gerçek ve tüzel kişiler İYS'ye kaydolur.</p>
                              <p>(3) İYS üzerinde onayı bulunmayan alıcılara ticari elektronik
                                ileti gönderilemez."</p>
                              <p><strong>MADDE 5 -</strong> Aynı Yönetmeliğin 6 ncı
                                maddesine aşağıdaki fıkralar eklenmiştir.</p>
                              <p>"(5) Birinci, ikinci ve dördüncü fıkralar
                                kapsamında ileti gönderilmesi halinde İYS üzerinden
                                kontrol yapılmaz.</p>
                              <p>(6) Üçüncü fıkra kapsamında ileti
                                gönderilmesinden önce tacir veya esnaf olan alıcıların
                                elektronik iletişim adresleri hizmet sağlayıcı tarafından
                                İYS'ye kaydedilir ve İYS üzerinden alıcıların ret hakkını
                                kullanıp kullanmadığı kontrol edilir."</p>
                              <p><strong>MADDE 6 -</strong> Aynı Yönetmeliğin 7 nci
                                maddesinin birinci fıkrasında yer alan "her türlü
                                elektronik iletişim aracıyla" ibaresinden sonra gelmek
                                üzere "ya da İYS üzerinden" ibaresi eklenmiş
                                ve aynı fıkranın sonuna aşağıdaki cümle eklenmiş, aynı maddenin
                                üçüncü fıkrasında yer alan "aynı
                                gün" ibaresi "24 saat" olarak değiştirilmiş
                                ve aynı fıkranın sonuna aşağıdaki cümle eklenmiş, aynı maddenin
                                onuncu fıkrası aşağıdaki şekilde değiştirilmiş ve aynı maddeye
                                aşağıdaki fıkralar eklenmiştir.</p>
                              <p>"İYS üzerinden alınan onaylarda ise olumlu irade beyanı
                                ve elektronik iletişim adresi yer alır."</p>
                              <p>"İYS üzerinden alınan onaylara bu fıkra
                                hükümleri uygulanmaz."</p>
                              <p>"(10) İYS üzerinden alınmayan onaylarda, onayın
                                alındığına ilişkin ispat yükümlülüğü hizmet
                                sağlayıcıya aittir."</p>
                              <p>"(11) İYS üzerinden alınmayan onaylar, hizmet sağlayıcı
                                tarafından üç iş günü içinde
                                İYS'ye kaydedilir.</p>
                              <p>(12) İYS'ye kaydedilmeyen onaylar geçersiz kabul
                                edilir."</p>
                              <p><strong>MADDE 7 - </strong>Aynı Yönetmeliğin 8 inci
                                maddesinin ikinci ve üçüncü fıkralarında yer
                                alan "T.C." ibarelerinden sonra gelmek üzere
                                "veya vergi" ibareleri eklenmiş, aynı maddenin
                                üçüncü fıkrasından sonra gelmek üzere
                                aşağıdaki fıkra eklenmiş ve diğer fıkralar buna göre
                                teselsül ettirilmiştir.</p>
                              <p>"(4) Sesli arama içeriğinde; tacirler için
                                ticaret unvanına, esnaflar için ise adı ve soyadına yer
                                verilir. Hizmet sağlayıcı, bunlara ek olarak marka veya işletme adı
                                gibi kendisini tanıtan diğer bilgilere yer verebilir."</p>
                              <p><strong>MADDE 8 - </strong>Aynı Yönetmeliğin 9 uncu
                                maddesinin üçüncü fıkrasının birinci
                                cümlesi aşağıdaki şekilde değiştirilmiş ve aynı maddeye
                                aşağıdaki fıkralar eklenmiştir.</p>
                              <p>"Hizmet sağlayıcı, alıcının ret bildiriminde bulunabilmesi
                                için kendisi tarafından sağlanan ya da İYS tarafından sunulan
                                müşteri hizmetleri numarası, kısa mesaj numarası veya yalnızca
                                ret bildirimine özgülenmiş bir URL adresi gibi
                                erişilebilir iletişim adresine ticari elektronik iletide yer
                                verir."</p>
                              <p>"(6) Hizmet sağlayıcı, kendisine iletilen ret bildirimlerini
                                üç iş günü içinde İYS'ye
                                bildirir.</p>
                              <p>(7) Alıcı reddetme hakkını İYS üzerinden de
                                kullanabilir."</p>
                              <p><strong>MADDE 9 - </strong>Aynı Yönetmeliğin 10 uncu
                                maddesinden sonra gelmek üzere aşağıdaki 10/A maddesi
                                eklenmiştir.</p>
                              <p>"<strong>Ticari elektronik ileti yönetim
                                  sistemi</strong></p>
                              <p><strong>MADDE 10/A - </strong>(1) Kuruluş, onay ve ret
                                bilgilerinin İYS'ye kaydedilmesi, İYS üzerinden onay
                                alınması, reddetme hakkının kullanılması, ticari elektronik ileti
                                şikâyetlerinin alınması, raporlanması, şikâyet
                                sürecinin hızlı ve etkin bir şekilde yönetilmesi ve aracı
                                hizmet sağlayıcıların sistemi kullanması amacıyla teknik altyapıyı
                                hazırlar ve Bakanlığın erişimine açar.</p>
                              <p>(2) Kuruluş, ticari elektronik ileti gönderiminin aksamaması
                                için gerekli teknik tedbiri alır.</p>
                              <p>(3) Kuruluş İYS'ye yönelik Bakanlık tarafından istenen
                                diğer iş ve işlemleri yürütmekle
                                yükümlüdür."</p>
                              <p><strong>MADDE 10 - </strong>Aynı Yönetmeliğin 11 inci
                                maddesi başlığı ile birlikte aşağıdaki şekilde değiştirilmiştir.</p>
                              <p>"<strong>Aracı hizmet sağlayıcılara ilişkin
                                  yükümlülükler</strong></p>
                              <p><strong>MADDE 11 - </strong>(1) Hizmet sağlayıcı,
                                önceden onayını aldığı alıcılara ticari elektronik iletileri
                                kendisi gönderebileceği gibi aracı hizmet sağlayıcılar
                                vasıtasıyla da gönderebilir.</p>
                              <p>(2) Aracı hizmet sağlayıcı, bu Yönetmelikle hizmet sağlayıcı
                                için öngörülen
                                yükümlülüklerin ifasına yönelik teknik
                                imkân tanır.</p>
                              <p>(3) Aracı hizmet sağlayıcı, hizmet sunduğu elektronik ortamı
                                kullanan gerçek ve tüzel kişiler tarafından sağlanan
                                içerikleri kontrol etmek, bu içerik ve içeriğe
                                konu mal veya hizmetle ilgili hukuka aykırı bir faaliyetin ya da
                                durumun söz konusu olup olmadığını araştırmakla
                                yükümlü değildir.</p>
                              <p>(4) Aracı hizmet sağlayıcı başkaları adına, onların mal ve
                                hizmetlerini tanıtmak, pazarlamak ya da işletmesini tanıtmak
                                amacıyla ticari elektronik ileti göndermek için onay
                                alamaz.</p>
                              <p>(5) Aracı hizmet sağlayıcı ticari elektronik ileti gönderimine
                                ilişkin sistemini İYS ile uyumlaştırır.</p>
                              <p>(6) Aracı hizmet sağlayıcı, İYS'ye kayıt olmayan hizmet
                                sağlayıcılara ait ticari elektronik iletilerin gönderimini
                                başlatmaz.</p>
                              <p>(7) Hizmet sağlayıcının talimatı doğrultusunda ticari elektronik
                                ileti gönderimini başlatan aracı hizmet sağlayıcı, ileti
                                gönderimine başlamadan önce İYS üzerinden alıcıların
                                onayının olup olmadığını kontrol eder ve İYS üzerinde onayı
                                bulunmayan alıcılara ileti gönderimini başlatmaz.</p>
                              <p>(8) Hizmet sağlayıcının 6 ncı maddenin birinci, ikinci ve
                                dördüncü fıkraları kapsamında ileti gönderiminde
                                bulunmak istemesi halinde aracı hizmet sağlayıcı tarafından bu
                                yönde beyan alınır.</p>
                              <p>(9) Aracı hizmet sağlayıcı, alıcıların abonelik hattını kapatmaları
                                halinde İYS'ye bu durumu bildirir."</p>
                              <p><strong>MADDE 11 - </strong>Aynı Yönetmeliğin 12 nci
                                maddesinin birinci fıkrasında yer alan "aracı hizmet
                                sağlayıcı" ibaresinden sonra gelmek üzere "ile
                                Kuruluş" ibaresi eklenmiştir.</p>
                              <p><strong>MADDE 12 - </strong>Aynı Yönetmeliğin 13
                                üncü maddesinin ikinci fıkrasında yer alan "bir
                                yıl" ibaresi "üç yıl" olarak
                                değiştirilmiştir.</p>
                              <p><strong>MADDE 13 - </strong>Aynı Yönetmeliğin 14
                                üncü maddesinin birinci fıkrasında yer alan
                                "e-Devlet kapısı" ibaresinden sonra gelmek üzere
                                ", İYS" ibaresi eklenmiş, aynı maddenin ikinci
                                fıkrasının (a) bendinde yer alan "ve içeriğinin
                                tamamı" ibaresi ", iletinin görsel bir
                                örneği" olarak değiştirilmiş ve aynı bendin ikinci
                                cümlesi yürürlükten kaldırılmış, aynı fıkranın
                                (b) bendinin son cümlesinde yer alan "İletinin"
                                ibaresinden sonra gelmek üzere "görsel"
                                ibaresi eklenmiş ve aynı fıkranın (c) bendinde yer alan
                                "içeriği" ibaresi "konusu" olarak
                                değiştirilmiştir.</p>
                              <p><strong>MADDE 14 - </strong>Aynı Yönetmeliğin 15 inci
                                maddesinin ikinci fıkrası aşağıdaki şekilde değiştirilmiş ve aynı
                                maddeye aşağıdaki fıkra eklenmiştir.</p>
                              <p>"(2) Başvuru, öncelikle İYS üzerinden ön
                                incelemeye tabi tutulur. Ön inceleme sonrasında il
                                müdürlüğü tarafından konuya ilişkin bilgi ve
                                belgeler ilgilisinden temin edilerek şikâyet
                                sonuçlandırılır. Ancak gerekli hallerde il
                                müdürlüğünce denetim için
                                görevlendirilen personel tarafından yerinde denetim
                                yapılır."</p>
                              <p>"(5) Hizmet sağlayıcının talimatı doğrultusunda ileti
                                gönderimini başlatan aracı hizmet sağlayıcı, şikâyet
                                başvurularına konu iletilere ilişkin bilgi ve belge taleplerini İYS
                                üzerinden on beş gün içinde yanıtlar."</p>
                              <p><strong>MADDE 15 -</strong> Aynı Yönetmeliğin 16 ncı
                                maddesinin birinci fıkrası aşağıdaki şekilde değiştirilmiştir.</p>
                              <p>"(1) Bakanlık, hizmet sağlayıcı, aracı hizmet sağlayıcı ve
                                Kuruluşun bu Yönetmelik kapsamında gerçekleştirdiği
                                faaliyet ve işlemlerini denetlemeye yetkilidir."</p>
                              <p><strong>MADDE 16 - </strong>Aynı Yönetmeliğin
                                geçici 1 inci maddesinden önce gelmek üzere
                                aşağıdaki 17/A maddesi eklenmiştir.</p>
                              <p>"<strong>Elektronik posta yoluyla gönderilen ticari
                                  elektronik iletiler</strong></p>
                              <p><strong>MADDE 17/A -</strong> (1) Elektronik posta yoluyla
                                gönderilen ticari elektronik iletilere, 11 nci maddenin
                                beşinci, altıncı, yedinci ve sekizinci fıkraları ile 15 inci
                                maddenin beşinci fıkrası hükümleri uygulanmaz."</p>
                              <p><strong>MADDE 17 - </strong>Aynı Yönetmeliğe aşağıdaki
                                geçici madde eklenmiştir.</p>
                              <p>"<strong>Mevcut veri tabanlarının İYS'ye
                                  yüklenmesi</strong></p>
                              <p><strong>GEÇİCİ MADDE 2 -</strong> (1) Bu
                                Yönetmelik kapsamında alınan onaylar 1/6/2020 tarihine kadar
                                hizmet sağlayıcılar tarafından İYS'ye aktarılır. Bu
                                sürenin sonunda İYS tarafından alıcılara onayların İYS'ye
                                yüklendiği ve 1/9/2020 tarihine kadar kontrol edilmediği
                                takdirde bu onayların geçerli sayılacağı ile reddetme
                                imkanının İYS üzerinden kullanılabileceği bilgisini
                                içeren bir ileti gönderilir.</p>
                              <p>(2) Alıcılar, İYS'ye kaydedilen onayları 1/9/2020 tarihine
                                kadar kontrol eder. Bu sürenin bitiminden sonra gönderilen
                                ticari elektronik iletiler onaylı kabul edilir.</p>
                              <p>(3) Bakanlık bu tarihleri üç ay ertelemeye
                                yetkilidir."</p>
                              <p><strong>MADDE 18 -</strong> Aynı Yönetmeliğin 19 uncu
                                maddesi aşağıdaki şekilde değiştirilmiştir.</p>
                              <p>"<strong>MADDE 19 - </strong>(1) Bu Yönetmelik
                                hükümlerini Ticaret Bakanı yürütür."
                              </p>
                              <p><strong>MADDE 19 - </strong>Bu Yönetmeliğin; 1 inci, 2
                                nci, 3 üncü, 7 nci, 9 uncu, 11 inci, 12 nci, 13
                                üncü, 15 inci, 17 nci ve 18 inci maddeleri yayımı
                                tarihinde, diğer maddeleri 1/9/2020 tarihinde
                                yürürlüğe girer.</p>
                              <p><strong>MADDE 20 -</strong> Bu Yönetmelik
                                hükümlerini Ticaret Bakanı yürütür.</p>
                              <div><br><br>
                                <table border="1" cellspacing="0" cellpadding="0" class="kanun">
                                  <tbody>
                                    <tr>
                                      <td colspan="2"  >
                                        <p><strong>Yönetmeliğin Yayımlandığı Resmî
                                            Gazete'nin</strong></p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="284">
                                        <p><strong>Tarihi</strong></p>
                                      </td>
                                      <td width="283">
                                        <p><strong>Sayısı</strong></p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="284">
                                        <p>15/7/2015</p>
                                      </td>
                                      <td width="283">
                                        <p>29417</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>



        <div class="pagelink">
          <a target="_blank" href="https://www.resmigazete.gov.tr/eskiler/2020/01/20200104-2.htm" >Resmî Gazete Üzerinde Görüntüle</a>
        </div>
        <!-- <div class="pagelink">
          <a target="_blank" href="https://www.resmigazete.gov.tr/eskiler/2015/07/20150715-4.htm" >15 Temmuz 2015 Tarihli Yönetmeliği Görüntüle</a>
        </div> -->
      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <h3>Mevzuat</h3>
        <div class="half">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second"><router-link to="/iys/kanun">Görüntüle</router-link></div>
        </div>
        <div class="half">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k</p>
          <div class="btn second"><router-link to="/iys/yonetmelik">Görüntüle</router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Vue from "vue";

export default {
  name: "IysNedir",

  methods: {
    ...mapMutations("app", ["SET_MODAL"])
  }
};
</script>

<style scoped>
.staticbox:nth-child(odd) {
  flex-direction: row-reverse;
  text-align: right;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
